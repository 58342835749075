import Analytics from "../../analytics";

// action types
export const ADD_SHARER = "ADD_SHARER";
export const TOGGLE_POPOVER = "TOGGLE_POPOVER";

export const addSharer = sharer => ({
  type: ADD_SHARER,
  sharer
});

export const togglePopover = (buttonElement, isActive) => ({
  type: TOGGLE_POPOVER,
  buttonElement,
  isActive
});

function sendGTMEvent(action, label) {
  Analytics.track("click-interaction-button", {
    action,
    label
  });
}

function shouldOpenPopover(sharer, clickedElement) {
  // toggle a sharer's active status
  // there may be more than 1 of an item on a page, so compare the button
  // allows closing a popover by:
  // 1. clicking the same share button again
  // 2. clicking a different share button
  // 3. clicking outside the popover
  const buttonMatch = sharer.buttonElement === clickedElement;
  return sharer.isActive ? !sharer.isActive : buttonMatch;
}

export const sendShareEvent = clickedElement => {
  return function doEvent(dispatch, getState) {
    const { sharers } = getState().sharingReducer;
    sharers.map(sharer => {
      const isActive = shouldOpenPopover(sharer, clickedElement);

      if (isActive) {
        sendGTMEvent("share intent for item", sharer.permalink);
      }

      return dispatch(togglePopover(sharer.buttonElement, isActive));
    });
  };
};

export const sendClipEvent = label => {
  sendGTMEvent("share permalink for item", label);
};
