import { ADD_SHARER, TOGGLE_POPOVER } from "./actions";

const initialSharingState = {
  sharers: []
};

function updateItem(sharer, action) {
  return {
    ...sharer,
    isActive: action.isActive
  };
}

function sharingReducer(state = initialSharingState, action) {
  switch (action.type) {
    case ADD_SHARER:
      return {
        ...state, // collect sharing data about this item
        sharers: [...state.sharers, action.sharer]
      };
    case TOGGLE_POPOVER:
      return {
        ...state, // find and update the item's data, otherwise leave it be
        sharers: state.sharers.map(sharer => {
          return sharer.buttonElement === action.buttonElement ? updateItem(sharer, action) : sharer;
        })
      };
    default:
      return state;
  }
}
export default sharingReducer;
