// Generic JS Utilities

/* eslint-disable */
// for IE11 ad support with Venatus, TODO: remove once it's included on their end
// https://developer.mozilla.org/en-US/docs/Web/API/ParentNode/append
// Source: https://github.com/jserz/js_piece/blob/master/DOM/ParentNode/append()/append().md
(function(arr) {
  arr.forEach(function(item) {
    if (item.hasOwnProperty("append")) {
      return;
    }
    Object.defineProperty(item, "append", {
      configurable: true,
      enumerable: true,
      writable: true,
      value: function append() {
        var argArr = Array.prototype.slice.call(arguments),
          docFrag = document.createDocumentFragment();

        argArr.forEach(function(argItem) {
          var isNode = argItem instanceof Node;
          docFrag.appendChild(
            isNode ? argItem : document.createTextNode(String(argItem))
          );
        });

        this.appendChild(docFrag);
      }
    });
  });
})([Element.prototype, Document.prototype, DocumentFragment.prototype]);
/* eslint-enable */

/* eslint-disable */
(function() {
  // https://developer.mozilla.org/en-US/docs/Web/API/CustomEvent/CustomEvent#Polyfill
  if (typeof window.CustomEvent === "function") return false;
  function CustomEvent(event, params) {
    const options = params || {
      bubbles: false,
      cancelable: false,
      detail: null
    };
    const evt = document.createEvent("CustomEvent");
    evt.initCustomEvent(
      event,
      options.bubbles,
      options.cancelable,
      options.detail
    );
    return evt;
  }
  CustomEvent.prototype = window.Event.prototype;
  window.CustomEvent = CustomEvent;
})();
/* eslint-enable */

/* eslint-disable */
(function() {
  // https://developer.mozilla.org/en-US/docs/Web/API/Element/closest#Polyfill
  if (!Element.prototype.matches) {
    Element.prototype.matches =
      Element.prototype.msMatchesSelector ||
      Element.prototype.webkitMatchesSelector;
  }
  if (!Element.prototype.closest) {
    Element.prototype.closest = function(s) {
      var el = this;
      do {
        if (el.matches(s)) return el;
        el = el.parentElement || el.parentNode;
      } while (el !== null && el.nodeType === 1);
      return null;
    };
  }
})();
/* eslint-enable */

// Script Loader Promise
export const LoadScriptAsync = (() => {
  // https://davidwalsh.name/javascript-loader
  // This promise will be used by Promise.all to determine success or failure
  function loadType(tag) {
    return url => {
      const promise = new Promise((resolve, reject) => {
        const element = document.createElement(tag);
        let parent = "body";
        let attr = "src";

        // Important success and error for the promise
        element.onload = () => {
          resolve(url);
        };
        element.onerror = () => {
          reject(url);
        };

        // Need to set different attributes depending on tag type
        switch (tag) {
          case "script":
            element.async = true;
            element.type = "text/javascript";
            break;
          case "link":
            element.type = "text/css";
            element.rel = "stylesheet";
            attr = "href";
            parent = "head";
            break;
          default:
            // assume script as default
            element.async = true;
            break;
        }

        // Inject into document to kick off loading
        element[attr] = url;
        document[parent].appendChild(element);
      });
      return promise;
    };
  }

  return {
    css: loadType("link"),
    js: loadType("script")
  };
})();

// Image Loaded Promise
export const ImageLoadedSniffer = image => {
  // Verify we're checking against a url, get it if needed
  const imgURL = typeof image === "string" ? image : image.src;

  // Check for load event
  const promise = new Promise((resolve, reject) => {
    const img = new Image();
    img.onload = () => {
      resolve(imgURL);
    };
    img.onerror = () => {
      reject(imgURL);
    };
    img.src = imgURL;
  });
  return promise;
};

// Shuffle the array and return a fresh one
export const GetNewShuffledArray = array => {
  const newArray = array.slice();
  for (let i = newArray.length - 1; i > 0; i -= 1) {
    const random = Math.floor(Math.random() * (i + 1));
    [newArray[i], newArray[random]] = [newArray[random], newArray[i]];
  }
  return newArray;
};

export const PollConditionForTrue = (
  condition,
  interval = 100,
  failsafe = 3000
) =>
  // returns a promise once condition evaluates to true
  // polls condition every interval ms
  // condition should be a function so it can be polled and reevaluated
  // will reject the promise after failsafe ms or if condition is not a function

  new Promise((resolve, reject) => {
    const checkCondition = () =>
      condition() ? resolve(true) : setTimeout(checkCondition, interval);
    checkCondition();

    setTimeout(
      () => reject(new Error(`hit failsafe timout for: ${condition}`)),
      failsafe
    );
  });

// Checks if the current user is a bot or not
export const IsABot = () => {
  // This can be modified if needed for more advanced bot detection
  const canScroll = "onscroll" in window;
  const hasBotUserAgent = /(gle|ing)bot/.test(navigator.userAgent);
  return !canScroll && hasBotUserAgent;
};

// Load the IntersectionObserver Polyfill if it's needed
export const LoadIntersectionObserver = () => {
  const promise = new Promise((resolve, reject) => {
    if (
      "IntersectionObserver" in window &&
      "IntersectionObserverEntry" in window &&
      "intersectionRatio" in window.IntersectionObserverEntry.prototype &&
      "isIntersecting" in window.IntersectionObserverEntry.prototype
    ) {
      // This browser is good
      resolve("Intersection Observer is supported");
    } else {
      // This browser needs the polyfill
      import(
        /* webpackChunkName: "observer", webpackMode: "lazy" */ "intersection-observer"
      )
        .then(() => {
          // Great, it's been imported, either this time or previously
          resolve("Intersection Observer is polyfilled");
        })
        .catch(() => reject(new Error("Intersection Observer is not loaded")));
    }
  });
  return promise;
};

// Request Throttled Animation Frame Listener
export const RequestThrottledFrame = (event, callback, obj) => {
  // To init:
  // RequestThrottledFrame('resize', 'optimizedResize');

  // To bind to throttled events:
  // window.addEventListener('optimizedResize', () => {
  //   runSomething();
  // });

  // Note:
  // If multiple events with the same name are bound to the same element (such as `window`),
  // then events will be duplicated. Either bind to the element you actually need,
  // or define the event in a unique way, like 'optimizedScroll--Nav' or 'optimizedScroll-GTM'
  /* eslint-disable-next-line max-len */
  const object = obj || window;
  const throttle = (type, name) => {
    let running = false;
    const func = () => {
      if (running) {
        return;
      }
      running = true;
      requestAnimationFrame(() => {
        object.dispatchEvent(new CustomEvent(name));
        running = false;
      });
    };
    object.addEventListener(type, func);
  };

  // Init Event/s
  throttle(event, callback, object);
};
/* eslint-enable-next-line max-len */

export const CompareAgainstThreshold = (number, thresholdArray) => {
  let result;
  if (thresholdArray === undefined) {
    // don't filter it
    result = number;
  } else {
    // compare against the array, return the min threshold that's been scrolled past
    result = thresholdArray.reduce(
      (accumulator, currentValue) =>
        number >= currentValue ? currentValue : accumulator,
      0
    );
  }
  return result;
};

export const GetScrollPercent = () => {
  let percentScrolled = 0;

  const docElement = document.documentElement;
  const docBody = document.body;

  const getDocHeight = () =>
    Math.max(
      docBody.scrollHeight,
      docElement.scrollHeight,
      docBody.offsetHeight,
      docElement.offsetHeight,
      docBody.clientHeight,
      docElement.clientHeight
    );
  const winheight = window.innerHeight || (docElement || docBody).clientHeight;
  const scrollTop =
    window.pageYOffset ||
    (docElement || docBody.parentNode || docBody).scrollTop;
  const trackLength = getDocHeight() - winheight;

  // if trackLength is 0, then the section cannot be scrolled, so we set the result to 100%
  percentScrolled =
    trackLength === 0 ? 100 : Math.floor((scrollTop / trackLength) * 100);
  return percentScrolled;
};

export const SetViewportSizes = () => {
  // Get viewport height and width, multiply it by 1% to get the vh and vw unit values
  // This is useful for mobile devices which will likely show extra toolbars
  const vh = window.innerHeight * 0.01;
  const vw = window.innerWidth * 0.01;

  // Set --vh and --vw custom properties to the root of the document
  document.documentElement.style.setProperty("--vh", `${vh}px`);
  document.documentElement.style.setProperty("--vw", `${vw}px`);
};

export const SmoothScrollToTarget = (target, offset = 0) => {
  // Be sure to require the polyfill and then init it:
  //   import smoothscroll from 'smoothscroll-polyfill';
  //   smoothscroll.polyfill();
  // We don't import it here since it'd be auto included with other imported utilities

  const getCoords = element => {
    const box = element.getBoundingClientRect();
    return {
      top: box.top + window.pageYOffset,
      left: box.left + window.pageXOffset
    };
  };

  // const navOffset = 75;
  const position = getCoords(target).top;

  window.scroll({ top: position + offset, behavior: "smooth" });
};
