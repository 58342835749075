import { ADD_COMMENTER, LOAD_DISQUS, SET_COMMENT_COUNT, SET_CURRENT_THREAD, CLOSE_THREAD } from "./actions";

const initialCommentingState = {
  commenters: [],
  disqusLoaded: false
};

function updateItemCount(commenter, action) {
  // update the comment count
  return {
    ...commenter,
    count: action.count
  };
}

function toggleCurrentThread(commenter, action) {
  // toggle an item's thread status
  const currentItem = commenter.item === action.item;
  const alreadyOpen = commenter.currentThread;
  return {
    ...commenter,
    currentThread: currentItem && !alreadyOpen
  };
}

function closeCurrentThread(commenter) {
  // set the current thread to false
  return {
    ...commenter,
    currentThread: false
  };
}

function commentingReducer(state = initialCommentingState, action) {
  switch (action.type) {
    case ADD_COMMENTER:
      return {
        ...state, // collect commenting data about this item
        commenters: [...state.commenters, action.commenter]
      };
    case LOAD_DISQUS:
      return { ...state, disqusLoaded: action.disqusLoaded };
    case SET_COMMENT_COUNT:
      return {
        ...state,
        commenters: state.commenters.map(commenter => {
          return commenter.item === action.item ? updateItemCount(commenter, action) : commenter;
        })
      };
    case SET_CURRENT_THREAD:
      return {
        ...state,
        commenters: state.commenters.map(commenter => {
          return toggleCurrentThread(commenter, action);
        })
      };
    case CLOSE_THREAD:
      return {
        ...state,
        commenters: state.commenters.map(commenter => {
          return commenter.item === action.item ? closeCurrentThread(commenter) : commenter;
        })
      };
    default:
      return state;
  }
}
export default commentingReducer;
