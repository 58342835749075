import Analytics from "analytics";
import googleTagManager from "@analytics/google-tag-manager";

// https://tagmanager.google.com/#/container/accounts/1249831416/containers/11010595/
/*
In GTM:
  check triggers for available "event names", such as "click"
  triggers are mapped to event categories: engagement, visibility, or detection
  actions and labels can be any string, they are shown as custom dimensions in GA
  actions should be generic ("click buy button"), labels should be specific (product title)
  values are integers and must be tied to something measurable, like price
*/

// https://segment.com/docs/connections/sources/catalog/libraries/website/javascript/
// https://segment.com/docs/connections/destinations/catalog/google-analytics/
export default Analytics({
  app: "the-far-side",
  plugins: [
    googleTagManager({
      containerId: "GTM-NH4KKJ7"
    })
  ]
});
