import SVGInjector from "svg-injector";

function injectSVGs() {
  // https://github.com/iconic/SVGInjector
  const svgsToInject = document.querySelectorAll("img.js-svg-inject");
  const injectorOptions = {
    evalScripts: "once"
  };

  function doInjection() {
    requestAnimationFrame(() => {
      SVGInjector(svgsToInject, injectorOptions);
    });
  }

  if (svgsToInject.length > 0) {
    doInjection();
  }
}
export default injectSVGs;
