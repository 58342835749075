import axios from "axios";
import axiosRetry from "axios-retry";
import * as Sentry from "@sentry/browser";

axiosRetry(axios, { retries: 3 });

// This function fetches the csrf token from the token endpoint
// and then inserts into the head.
function initCsrfToken() {
  axios({
    method: "get",
    url: "/csrf_token.json"
  })
    .then(result => {
      const metaParam = document.createElement("meta");
      metaParam.name = "csrf-param";
      metaParam.content = result.data.param;

      const head = document.querySelector("head");
      head.appendChild(metaParam);

      const metaToken = document.createElement("meta");
      metaToken.name = "csrf-token";
      metaToken.content = result.data.token;
      head.appendChild(metaToken);
    })
    .catch(error => {
      // Capture exception if axios request fails three times
      Sentry.captureException(error);
    });
}

export default initCsrfToken;
