import MainNav from "./components/MainNav";
import Footer from "./components/Footer";

async function importComponents(store) {
  // components on every page
  MainNav.init();
  Footer.init();

  // components that might be on the page
  const landerImage = document.getElementsByClassName("js-lander-image");
  if (landerImage.length) {
    const { default: LanderImages } = await import(
      /* webpackChunkName: "LanderImages", webpackMode: "lazy" */ "./components/LanderImages"
    );
    LanderImages.init();
  }

  const splashImage = document.getElementsByClassName("js-splash-image");
  if (splashImage.length) {
    const { default: SplashImages } = await import(
      /* webpackChunkName: "SplashImages", webpackMode: "lazy" */ "./components/SplashImages"
    );
    SplashImages.init();
  }

  const newsletterForm = document.getElementsByClassName("js-newsletter-form");
  if (newsletterForm.length) {
    const { default: NewsletterForm } = await import(
      /* webpackChunkName: "NewsletterForm", webpackMode: "lazy" */ "./components/NewsletterForm"
    );
    NewsletterForm.init();
  }

  const banner = document.getElementsByClassName("js-banner");
  if (banner.length) {
    const { default: Banner } = await import(
      /* webpackChunkName: "Banner", webpackMode: "lazy" */ "./components/Banner"
    );
    Banner.init();
  }

  const subNav = document.getElementsByClassName("js-sub-nav");
  if (subNav.length) {
    const { default: SubNav } = await import(
      /* webpackChunkName: "SubNav", webpackMode: "lazy" */ "./components/SubNav"
    );
    SubNav.init();
  }

  const comic = document.getElementsByClassName("js-comic");
  if (comic.length) {
    const { default: Comic } = await import(/* webpackChunkName: "Comic", webpackMode: "lazy" */ "./components/Comic");
    Comic.init();
  }

  const comicNew = document.getElementsByClassName("js-comic-new");
  if (comicNew.length) {
    const { default: ComicNew } = await import(
      /* webpackChunkName: "ComicNew", webpackMode: "lazy" */ "./components/ComicNew"
    );
    ComicNew.init(store);
  }

  const productTeaser = document.getElementsByClassName("js-product-teaser");
  if (productTeaser.length) {
    const { default: ProductTeaser } = await import(
      /* webpackChunkName: "ProductTeaser", webpackMode: "lazy" */ "./components/ProductTeaser"
    );
    ProductTeaser.init();
  }

  const interactable = document.getElementsByClassName("js-interactable");
  if (interactable.length) {
    const { default: Commenting } = await import(
      /* webpackChunkName: "Commenting", webpackMode: "lazy" */ "./components/Commenting"
    );
    Commenting.init(store);

    const { default: Liking } = await import(
      /* webpackChunkName: "Liking", webpackMode: "lazy" */ "./components/Liking"
    );
    Liking.init(store);

    const { default: Sharing } = await import(
      /* webpackChunkName: "Sharing", webpackMode: "lazy" */ "./components/Sharing"
    );
    Sharing.init(store);
  }
}
export default importComponents;
