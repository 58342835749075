import { ADD_LIKER, GET_USERS_LIKES, TOGGLE_LIKE } from "./actions";

const initialLikingState = {
  likers: [],
  usersLikes: []
};

function updateItem(liker, action) {
  // toggle an item's liked status and update liked count
  return {
    ...liker,
    liked: !liker.liked,
    count: action.count
  };
}

function insertItem(array, action) {
  // add item to user's likes
  const newArray = array.slice();
  newArray.splice(0, 0, action.item);
  return newArray;
}

function removeItem(array, action) {
  // remove item from user's likes
  const newArray = array.slice();
  newArray.splice(newArray.indexOf(action.item), 1);
  return newArray;
}

function likingReducer(state = initialLikingState, action) {
  switch (action.type) {
    case ADD_LIKER:
      return {
        ...state, // collect liking data about this item
        likers: [...state.likers, action.liker]
      };
    case GET_USERS_LIKES:
      return {
        ...state, // which items has this user liked?
        usersLikes: action.usersLikes
      };
    case TOGGLE_LIKE:
      return {
        ...state, // find and update the item's data, otherwise leave it be
        likers: state.likers.map(liker => {
          return liker.item === action.item ? updateItem(liker, action) : liker;
        }),
        // update the user's likes with this item
        usersLikes: state.usersLikes.includes(action.item)
          ? removeItem(state.usersLikes, action)
          : insertItem(state.usersLikes, action)
      };
    default:
      return state;
  }
}
export default likingReducer;
