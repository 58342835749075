// Welcome to the Far Side

// Styles
import "../styles/tfs-base.scss";

// Node packages
import "core-js/stable"; // Babel Polyfill
import { parsed, contentLoaded, loaded } from "document-promises";

// Import what we use from Bootstrap
import "bootstrap/js/dist/alert";
import "bootstrap/js/dist/button";
// import "bootstrap/js/dist/carousel";
import "bootstrap/js/dist/collapse";
import "bootstrap/js/dist/dropdown";
import "bootstrap/js/dist/index";
// import "bootstrap/js/dist/modal";
// import "bootstrap/js/dist/popover";
// import "bootstrap/js/dist/scrollspy";
// import "bootstrap/js/dist/tab";
// import "bootstrap/js/dist/toast";
import "bootstrap/js/dist/tooltip";
import "bootstrap/js/dist/util";

// Application/Utility Scripts
// Follow conventions in https://github.com/airbnb/javascript#naming-conventions
import {
  RequestThrottledFrame,
  SetViewportSizes,
} from "../scripts/tfs-utilities";
import LazyImages from "../scripts/LazyImages";
import Analytics from "../scripts/analytics";
import injectSVGs from "../scripts/injectSVGs";
import registerServiceWorkers from "../scripts/registerServiceWorkers";
import initCsrfToken from "../scripts/initCsrfToken";
import protectImages from "../scripts/protectImages";
import initSentry from "../scripts/initSentry";
import initStickyBits from "../scripts/initStickyBits";

// Components and Pages
import importComponents from "../scripts/importComponents";
import importPages from "../scripts/importPages";

// Redux
import configureStore from "../scripts/redux/store";

const store = configureStore();

// https://webpack.js.org/guides/dependency-management/#requirecontext
// Automatically require icons and images when they're used in views
// true will search within subdirectories, false will skip subdirectories
require.context("images/", true);

// Register callback for throttling and listen to the throttled resize events
RequestThrottledFrame("resize", "optimizedResize");
window.addEventListener(
  "optimizedResize",
  () => {
    SetViewportSizes();
  },
  false
);

function unfreezeTheFarSide() {
  // Run ASAP
  const executeWhenLoading = async () => {
    SetViewportSizes();
    initSentry();
  };

  // Run when the DOM is ready, other resources like styles and images may still be loading
  const executeWhenInteractive = () => {
    const lazyLoader = new LazyImages();
    lazyLoader.init();

    initCsrfToken();
    injectSVGs();
    protectImages();
    initStickyBits();

    Analytics.page();
  };

  // Run when fully loaded, load event is about to fire
  const executeWhenComplete = () => {
    importComponents(store);
    importPages(store);
    registerServiceWorkers();
  };

  parsed.then(() => executeWhenLoading());
  contentLoaded.then(() => executeWhenInteractive());
  loaded.then(() => executeWhenComplete());
}
unfreezeTheFarSide();
