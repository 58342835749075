/* global DISQUS */
import axios from "axios";
import { LoadScriptAsync } from "../../tfs-utilities";
import Analytics from "../../analytics";

// action types
export const ADD_COMMENTER = "ADD_COMMENTER";
export const LOAD_DISQUS = "LOAD_DISQUS";
export const SET_COMMENT_COUNT = "SET_COMMENT_COUNT";
export const SET_CURRENT_THREAD = "SET_CURRENT_THREAD";
export const CLOSE_THREAD = "CLOSE_THREAD";

export const addCommenter = commenter => ({
  type: ADD_COMMENTER,
  commenter
});

export const loadDisqus = disqusLoaded => ({
  type: LOAD_DISQUS,
  disqusLoaded
});

export const setCommentCount = (item, count) => ({
  type: SET_COMMENT_COUNT,
  item,
  count
});

export const setCurrentThread = item => ({
  type: SET_CURRENT_THREAD,
  item
});

export const closeThread = item => ({
  type: CLOSE_THREAD,
  item
});

// Disqus data
const root = document.querySelector("html");
const disqusData = {
  apiKey: root.getAttribute("data-disqus-api-public-key"),
  apiUrl: root.getAttribute("data-disqus-api-url"),
  embedUrl: root.getAttribute("data-disqus-embed-url"),
  shortname: root.getAttribute("data-disqus-shortname")
};

export function collapseComments() {
  return (dispatch, getState) => {
    const { commenters } = getState().commentingReducer;

    commenters.forEach(commenter => {
      // find current thread and close it
      if (commenter.currentThread) {
        $(`#comments-${commenter.item}`).collapse("hide");
        dispatch(closeThread(commenter.item));
      }
    });
  };
}

function sendGTMEvent(action, label) {
  Analytics.track("click-interaction-button", {
    action,
    label
  });
}

function addComment(item) {
  return (dispatch, getState) => {
    const { commenters } = getState().commentingReducer;
    const currentItem = commenters.find(commenter => commenter.item === item);

    sendGTMEvent("comment posted on item", currentItem.permalink);

    // converts from string to number
    // if we get much larger counts, we may need to add improved parsing to render the right format
    // like from 1000 to 1k
    const numberCount = currentItem.count * 1;

    // this count is "dumb" to save on network calls for the user,
    // if actual data is needed, see https://disqus.com/api/docs/threads/update
    return dispatch(setCommentCount(item, numberCount + 1));
  };
}

function resetDisqus(commenter) {
  return dispatch => {
    sendGTMEvent("comment intent for item", commenter.permalink);

    DISQUS.reset({
      reload: true,
      config() {
        this.page.identifier = commenter.item;
        this.page.url = commenter.disqusPermalink;
        this.page.title = commenter.disqusPermalink;
        this.language = "en";
        this.callbacks.onNewComment = [
          () => {
            // https://help.disqus.com/en/articles/1717088-capture-disqus-commenting-activity-via-callbacks
            dispatch(addComment(commenter.item));
          }
        ];
      }
    });
  };
}

function initDisqus(commenter) {
  return dispatch => {
    LoadScriptAsync.js(`${disqusData.embedUrl}`)
      .then(() => {
        // mark as loaded
        const isLoaded = typeof DISQUS !== "undefined";
        return dispatch(loadDisqus(isLoaded));
      })
      .then(() => {
        // reset to verify it pulls the correct thread
        return dispatch(resetDisqus(commenter));
      });
  };
}

export function fetchDisqus(commenter) {
  return (dispatch, getState) => {
    const state = getState().commentingReducer;
    return state.disqusLoaded
      ? dispatch(resetDisqus(commenter))
      : dispatch(initDisqus(commenter));
  };
}

export const initCommentCounts = () => {
  return (dispatch, getState) => {
    // build the array of items to query Disqus
    const { commenters } = getState().commentingReducer;
    const identArray = [];
    commenters.map(commenter => {
      return identArray.push(`ident:${commenter.item}`);
    });

    axios({
      method: "get",
      url: `${disqusData.apiUrl}/threads/set.json`,
      params: {
        api_key: disqusData.apiKey,
        forum: disqusData.shortname,
        thread: identArray
      }
    }).then(result => {
      // receive and parse a response for each thread
      const responses = result.data.response;
      responses.forEach(response => {
        const item = response.identifiers[0];
        const count = response.posts;
        return dispatch(setCommentCount(item, count));
      });
    });
  };
};
