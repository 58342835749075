import { RequestThrottledFrame, SmoothScrollToTarget } from "../tfs-utilities";

const MainNav = {
  data: {
    navSelector: ".js-main-nav",
    mobileNavSelector: "#tfs-main-nav-target",
    scrollPreventionClass: "tfs-no-scroll",
    hideNavClass: "tfs-main-nav--scroll-down",
    scrollDataAttr: "data-last-scroll",
    stickyNav: ".js-stickybit"
  },

  init() {
    this.setNavHeight();
    this.handleOpenMobileNav();
    this.handleNavScroll();
  },

  setNavHeight() {
    const nav = document.querySelector(this.data.navSelector);
    // document.body.contains() because document.contains() doesn't work in IE
    if (document.body.contains(nav)) {
      const headerHeight = nav.offsetHeight;
      document.documentElement.style.setProperty("--header-height", `${headerHeight}px`);
    }
  },

  // Mobile nav open and close w/ Bootstrap events
  handleOpenMobileNav() {
    const mainBody = document.querySelector("body");
    const $mainNav = $(this.data.mobileNavSelector);
    const stickyNav = document.querySelector(this.data.stickyNav);

    $mainNav
      .on("show.bs.collapse", () => {
        if (!mainBody.classList.contains(this.data.scrollPreventionClass)) {
          requestAnimationFrame(() => {
            mainBody.classList.add(this.data.scrollPreventionClass);
            // Scroll nav to the top
            SmoothScrollToTarget(stickyNav);
          });
        }
      })
      .on("hide.bs.collapse", () => {
        if (mainBody.classList.contains(this.data.scrollPreventionClass)) {
          requestAnimationFrame(() => {
            mainBody.classList.remove(this.data.scrollPreventionClass);
          });
        }
      });
  },

  handleNavScroll() {
    const nav = document.querySelector(this.data.navSelector);
    // document.body.contains() because document.contains() doesn't work in IE
    if (document.body.contains(nav)) {
      this.setNavValues(nav, window.pageYOffset);
      RequestThrottledFrame("scroll", "optimizedScroll--Nav"); // register a listener for this scroll event
      window.addEventListener("optimizedScroll--Nav", () => this.animateNav(nav, window.pageYOffset));
    }
  },

  setNavValues(element, scrollPos) {
    element.setAttribute(this.data.scrollDataAttr, scrollPos);
  },

  animateNav(element, scrollPos) {
    const lastScrollPos = element.getAttribute(this.data.scrollDataAttr);
    const scrollingDown = scrollPos > lastScrollPos;
    const atTop = scrollPos <= 0; // overscroll can look like scrolling down, this prevents that

    requestAnimationFrame(() => {
      if (!atTop && scrollingDown && !element.classList.contains(this.data.hideNavClass)) {
        // scrolling down, hide the nav
        element.classList.add(this.data.hideNavClass);
      } else if ((atTop || !scrollingDown) && element.classList.contains(this.data.hideNavClass)) {
        // scrolling up or at the top, show the nav
        element.classList.remove(this.data.hideNavClass);
      }
    });

    // update the cached scroll position
    this.setNavValues(element, scrollPos);
  }
};
export default MainNav;
