function registerServiceWorkers() {
  if ("serviceWorker" in navigator && ["production", "staging"].includes(process.env.NODE_ENV)) {
    navigator.serviceWorker
      .register("/tfs-sw.js")
      .then(() => {
        // registration worked
      })
      .catch(() => {
        // registration failed
      });
  }
}

export default registerServiceWorkers;
