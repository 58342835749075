import axios from "axios";
import Analytics from "../../analytics";

// action types
export const ADD_LIKER = "ADD_LIKER";
export const GET_USERS_LIKES = "GET_USERS_LIKES";
export const TOGGLE_LIKE = "TOGGLE_LIKE";

export const addLiker = liker => ({
  type: ADD_LIKER,
  liker
});

export const getUsersLikes = usersLikes => ({
  type: GET_USERS_LIKES,
  usersLikes
});

export const toggleLike = (item, count) => ({
  type: TOGGLE_LIKE,
  item,
  count
});

function sendGTMEvent(action, label) {
  Analytics.track("click-interaction-button", {
    action,
    label
  });
}

export const sendLikeEvent = (item, previouslyLiked) => {
  return function doEvent(dispatch, getState) {
    const { likers } = getState().likingReducer;
    const currentItem = likers.find(liker => liker.item === item);

    // if the user liked it, unlike it and vice versa
    const increment = !previouslyLiked;

    sendGTMEvent(
      increment ? "like item" : "unlike item",
      currentItem.permalink
    );

    // post
    axios({
      method: "post",
      url: `/like/${item}/${increment}`,
      headers: {
        "X-CSRF-Token": document.head.querySelector('meta[name="csrf-token"]')
          .content
      }
    }).then(response => {
      // what's the new liked count?
      const count = response.data.count_display;
      dispatch(toggleLike(item, count));
    });
  };
};
