function protectImages() {
  const images = Array.prototype.slice.call(document.querySelectorAll("img"));
  images.map(image => {
    const element = image;
    const createListenerEvent = () => {
      // Prevent right click from opening the context menu
      /* eslint-disable no-console */
      element.oncontextmenu = () => {
        if (["staging", "production"].includes(process.env.NODE_ENV)) {
          return false;
        }

        return console.log("DEBUG - allowed right click");
      };
      /* eslint-enable no-console */
    };
    return createListenerEvent();
  });
}

export default protectImages;
