/* eslint-disable no-unused-vars */
async function importPages(store) {
  const dailyDose = document.getElementsByClassName("js-daily-dose");
  if (dailyDose.length) {
    const { default: DailyDose } = await import(
      /* webpackChunkName: "DailyDose", webpackMode: "lazy" */ "./pages/DailyDose"
    );
    DailyDose.init();
  }
}
/* eslint-enable no-unused-vars */
export default importPages;
