import { ImageLoadedSniffer, IsABot, LoadIntersectionObserver } from "./tfs-utilities";

class LazyImages {
  constructor(lazyClass, targetSRC, targetWidth, targetHeight, targetTiming) {
    // Allows customization around what class and data attributes to attach to
    this.lazyClass = lazyClass || "js-lazy-load";
    this.dataSRC = targetSRC || "data-src";
    this.dataWidth = targetWidth || "data-width";
    this.dataHeight = targetHeight || "data-height";
    this.dataTiming = targetTiming || "data-timing";
    this.images = document.querySelectorAll(`.${this.lazyClass}[${this.dataSRC}]`);
  }

  init() {
    if (IsABot()) {
      // Bot, load all images immediately
      Array.prototype.forEach.call(this.images, image => {
        this.loadImage(image);
      });
    } else {
      LoadIntersectionObserver()
        .then(() => {
          // Let's be lazy!
          this.createSkeletons();
          this.createObserver();
        })
        .catch(() => {
          // Something broke, load all images immediately
          Array.prototype.forEach.call(this.images, image => {
            this.loadImage(image);
          });
        });
    }
  }

  createObserver() {
    const config = {
      rootMargin: "200px 50px 200px 50px",
      threshold: 0
    };

    const observer = new IntersectionObserver((entries, self) => {
      Array.prototype.forEach.call(entries, entry => {
        if (entry.isIntersecting) {
          // Load the image and stop watching
          this.loadImage(entry.target);
          self.unobserve(entry.target);
        }
      });
    }, config);

    // Watch for images to come into view
    Array.prototype.forEach.call(this.images, image => {
      observer.observe(image);
    });
  }

  createSkeletons() {
    Array.prototype.forEach.call(this.images, image => {
      const img = image;
      const timing = img.getAttribute(this.dataTiming) || 500;
      const width = img.getAttribute(this.dataWidth) || 1;
      const height = img.getAttribute(this.dataHeight) || 1;
      const aspectRatio = width / height; // fallback ratio will be 1:1

      // Calculate computed width and height
      const actualWidth = LazyImages.getTrueImageWidth(img);
      const actualHeight = actualWidth / aspectRatio;

      // Set width and height, plus add a background skeleton color
      LazyImages.applySkeletonStyles(img, actualWidth, actualHeight, timing);
    });
  }

  loadImage(imageToLoad) {
    const img = imageToLoad;
    const src = img.getAttribute(this.dataSRC);

    // Bail out, there's no src
    if (!src) {
      return;
    }

    // It's good, load it up
    ImageLoadedSniffer(src).then(() => {
      img.src = src;
      LazyImages.removeSkeletonStyles(img, this.lazyClass);
    });
  }

  // Class Utilities
  static getTrueImageWidth(image) {
    // image.clientWidth includes padding, this calculates width minus padding
    const styles = window.getComputedStyle(image);
    const padding = parseFloat(styles.paddingLeft) + parseFloat(styles.paddingRight);
    return image.clientWidth - padding;
  }

  static applySkeletonStyles(image, width, height, timing) {
    const img = image;
    requestAnimationFrame(() => {
      img.style.width = `${width}px`;
      img.style.height = `${height}px`;
      img.style.visibility = "hidden";
      img.style.opacity = 0;
      img.style.transition = `opacity ease-in-out ${timing}ms`;
    });
  }

  static removeSkeletonStyles(image, classToRemove) {
    // Verify there are styles to remove, bots don't have skeletons
    if (IsABot()) {
      return;
    }

    const img = image;
    requestAnimationFrame(() => {
      img.classList.remove(classToRemove);
      // don't override other sizing concerns in the slider
      if (!img.classList.contains("js-slider-image")) {
        img.style.width = "";
        img.style.height = "";
      }
      img.style.visibility = "visible";
      img.style.opacity = 1;
    });
  }
}
export default LazyImages;
