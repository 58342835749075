import { combineReducers } from "redux";
import commentingReducer from "./commenting/reducer";
import likingReducer from "./liking/reducer";
import sharingReducer from "./sharing/reducer";

const rootReducer = combineReducers({
  commentingReducer,
  likingReducer,
  sharingReducer
});

export default rootReducer;
