import { createStore, applyMiddleware } from "redux";
import thunk from "redux-thunk";
import rootReducer from "./reducers";

export default function configureStore() {
  const store = createStore(rootReducer, applyMiddleware(thunk));

  // For debugging the current state
  /* eslint-disable no-console */
  // if (process.env.NODE_ENV === "development") {
  //   store.subscribe(() => console.log(store.getState()));
  // }
  /* eslint-enable no-console */

  return store;
}
