const Footer = {
  data: {
    footerSelector: ".js-footer"
  },

  init() {
    this.setFooterHeight();
  },

  setFooterHeight() {
    const footer = document.querySelector(this.data.footerSelector);
    // document.body.contains() because document.contains() doesn't work in IE
    if (document.body.contains(footer)) {
      const footerHeight = footer.clientHeight;
      document.documentElement.style.setProperty("--footer-height", `${footerHeight}px`);
    }
  }
};
export default Footer;
